import * as React from 'react';
import { useTranslation } from 'react-i18next'

import './ColumnGradeResults.css'

import {get} from '../../utils/http-utils'

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TableDemo from '../../components/core/Table2';
import CircularIndeterminate from '../../components/core/loader';

import {columnGradeResultsColumns,deviceNums} from './constants'

import { deviceNumsContext } from '../../contexts/deviceNumContext'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Toast from '../../components/UtilComps/Toast';

const Home = (props) => {

    const { deviceNumbers } = React.useContext(deviceNumsContext)

    const { t } = useTranslation();
    const [ fromDate, setFromDate] = React.useState('')
    const [ toDate , setToDate] = React.useState('')
    const [pages, setPages] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [tableData, setTableData] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1)
    const [showToast,setShowToast] = React.useState(false)
    const [toastMsg,setToastMsg] = React.useState('')

    // const [ deviceNumbers, setDeviceNumbers] = React.useState([])
    const [ sampleIds, setSampleIds ] = React.useState([])
    const [ barcodes, setBarcodes] = React.useState([])

    const [isLoading, setIsLoading] = React.useState(false)

    const [ deviceNumbersLoaded, setDeviceNumbersLoaded] = React.useState(true)
    const [ barcodesLoaded, setBarcodesLoaded ] = React.useState(true)
    const [ sampleIdsLoaded, setSampleIdsLoaded ] = React.useState(true)

    const [ selectDeviceNumErr, setDeviceNumberErr] = React.useState(false)

    const [deviceNumber,setDeviceNumber] = React.useState('')

    const [selectedBarcode, setSelectedBarcode ] = React.useState('')
    const [selectedSampleId, setSelectedSampleId ] = React.useState('')

    const [toastSpecs,setToastSpecs] = React.useState({})

    const handleToastClose = () => {
        setToastSpecs({})
    }
    

    const handleDateChange = (e,type = 'from') => {
        console.log('date changed ',e.target.value,type)
        if(type === 'from'){
            setFromDate(e.target.value)
        }else{
            setToDate(e.target.value)
        }
    }

    const handlePageNumberChange = (evt) => {
        let pgNo = evt.target.value.trim() ? Number(evt.target.value) : 0
        if(pgNo){
            if(pgNo > pages){
                setCurrentPage(pages)
            }else{
                setCurrentPage(pgNo)
            }
        }
    }

    const validateInputs = () => {
        const deviceNumberSelected = deviceNumber && deviceNumber.trim().length
        const barcodeSelected = selectedBarcode && selectedBarcode.trim().length
        const datesAreValid = new Date(toDate) > new Date(fromDate)
        return {res: deviceNumberSelected && barcodeSelected && datesAreValid, deviceNumberSelected, barcodeSelected, datesAreValid}
    }

    const triggerToast = (dn,bc,dt) => {
        if(!dn){
            setToastSpecs(({open:true, type: 'warning', msg: 'Please Select Device Number'}))
            return
        }else if(!dt) {
            setToastSpecs(({open:true, type: 'warning', msg: 'Please Select Valid Date Range'}))
            return
        }else if(!bc) {
            setToastSpecs(({open:true, type: 'warning', msg: 'Please Select Barcode'}))
            return
        }
    }

    const getResponse = async ( newValues = true ) => {
        if(newValues){
            let { res, deviceNumberSelected, barcodeSelected, datesAreValid } = validateInputs()
            if(!res){
                triggerToast(deviceNumberSelected,barcodeSelected, datesAreValid)
                return
            }
        }
        if( deviceNumber && deviceNumber.trim().length &&  fromDate && fromDate.trim().length &&  toDate && toDate.trim().length){
            setIsLoading(true)
            let numberOfPagesResponse
            if(newValues){
                let params = {
                    aPageSize: rowsPerPage,
                    bSN: deviceNumber,
                    dStartDate: fromDate,
                    eEndDate: toDate,
                    fBarcode: selectedBarcode,
                }
                numberOfPagesResponse = await get('/ColumnGradeResults/Pagination',params)
                setPages(numberOfPagesResponse.data)
            }
            let params2 = {
                bSN: deviceNumber,
                dStartDate: fromDate,
                eEndDate: toDate,
                fBarcode: selectedBarcode,
                pageNum: currentPage,
                pageSize: rowsPerPage,
            }
            let tableData = await get('/ColumnGradeResults',params2)

            console.log('numberofpages === ',numberOfPagesResponse)
            console.log(tableData)
            setTableData(tableData.data)

            // let ccr = await get('/ColumnGradeResults/Pagination',{
            //     aPageSize: 20,
            //     bSN:'60001011',
            //     dStartDate:'2021-01-01',
            //     eEndDate: '2022-12-12'
            // })

            // console.log('column grade results === ',ccr)
            // setCurrentPage(1)
            setDeviceNumberErr(false)
        }
        setIsLoading(false)
        // let ccd = await get('/ColumnGradeResults',{id:3412})
        // console.log('column grade results === ',ccd)
    }

    React.useEffect(() => {
        // getDeviceNumber()
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        setFromDate(firstDay.toISOString().split('T')[0])
        setToDate(new Date().toISOString().split('T')[0])
        console.log('devicenUmberr card',deviceNumberCard.current.props)
    },[])

    const updateCurrentPage = (pageNum) => {
        setCurrentPage(pageNum)   
    }

    const setNextPage = () => {
        setCurrentPage(currentPage + 1)
    }

    const setPrevPage = () => {
        setCurrentPage(currentPage - 1)
    }

    const fetchBarcodes = async () => {
        setBarcodesLoaded(false)
        let barcodes = await get('/Barcodes',{deviceNumber})
        console.log('barcodes === ',barcodes)
        setBarcodes(barcodes.data)
        setBarcodesLoaded(true)
    }
    

    const fetchSampleIds = async () => {
        setSampleIdsLoaded(false)
        let sampleIds = await get('/SampleIds',{aDeviceNumber:deviceNumber,bBarcode:selectedBarcode})
        console.log('sampleIds === ',sampleIds)
        setSampleIds(sampleIds.data)
        setSampleIdsLoaded(true)
    }

    // React.useEffect(() => {
    //     getResponse()
    // },[currentPage,pages])

    React.useEffect(() => {
        if(deviceNumber.trim().length){
            fetchBarcodes()
        }
    },[deviceNumber])

    const deviceNumberCard = React.useRef()
    return (
        <>
        {/* <CircularIndeterminate /> */}
        <Toast specs={toastSpecs} onClose={handleToastClose} />
            <div className="main-content">
                <div className='header-logo'>
                    {/* <div className='header-text'>{t('hello')}, {props.user.given_name}</div> */}
                    <div className='header-text'>Ortho</div>
                </div>

                <div className='body-content-wrapper'>
                    <div className='body-content-header'>
                        Column Grade Results
                    </div>

                    <div className='body-content'>
                    <div className='input-section row' style={{width:'100%'}}>
                        <div className='col-sm-12 col-md-10'>
                        <div className='row'>
                        <div className='col-sm-12 col-md-3' style={{marginTop:'5px',textAlign: 'left'}}>
                             <Card className='deviceNumberC' sx={{ minWidth: '100%'}} ref = {deviceNumberCard}>
                                <CardContent>
                                    <div className='card-label'>
                                        Device #
                                    </div>
                                    <div>
                                        <select style={{width:'100%'}} value={deviceNumber} onChange={(e) => setDeviceNumber(e.target.value)}>
                                            <option value=''>Select</option>
                                            {
                                                deviceNumbers.map(e => <option value={e.device_number} key={e.device_number}>{e.device_number}</option>)
                                            }
                                        </select>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>

                        <div className='col-sm-12 col-md-3' style={{marginTop:'5px', textAlign: 'left'}}>
                            <Card>
                                <CardContent>
                                    <div className='card-label'>
                                        From
                                    </div>
                                    <div>
                                        <input type= 'date' value={fromDate} onChange = { e => handleDateChange(e,'from')}/>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>

                        <div className='col-sm-12 col-md-3' style={{marginTop:'5px', textAlign: 'left'}}>
                            <Card>
                                <CardContent>
                                    <div className='card-label'>
                                        To
                                    </div>
                                    <div>
                                        <input type= 'date' value={toDate} onChange = { e => handleDateChange(e,'to')} />
                                    </div>
                                </CardContent>
                            </Card>
                        </div>

                        <div className='col-sm-12 col-md-3' style={{marginTop:'5px',  textAlign: 'left'}}>
                            <Card>
                                <CardContent>
                                    <div className='card-label'>
                                        Barcode
                                    </div>
                                    <div>
                                        {
                                            barcodesLoaded
                                            ? <select style={{width:'100%'}} value={selectedBarcode} onChange={(e) => setSelectedBarcode(e.target.value)}>
                                                    <option value=''>Select</option>
                                                    {
                                                        barcodes.length &&
                                                        barcodes.map( ({barcode}) => <option value={barcode} key={barcode}>{barcode}</option> )
                                                    }
                                                </select>
                                            : 'Loading...'
                                        }
                                    </div>
                                </CardContent>
                            </Card>
                        </div>


                        {/* <div className='col-sm-12 col-md-3' style={{marginTop:'5px',  textAlign: 'left'}}>
                            <Card>
                                <CardContent>
                                    <div className='card-label'>
                                        Sample ID
                                    </div>
                                    <div>
                                        {
                                            sampleIdsLoaded
                                            ? <select style={{width:'100%'}} value={selectedSampleId} onChange={(e) => setSelectedSampleId(e.target.value)}>
                                                    <option value=''>Select</option>
                                                    {
                                                        sampleIds.length &&
                                                        sampleIds.map( ({sample_id}) => <option value={sample_id} key={sample_id}>{sample_id}</option> )
                                                    }
                                                </select>
                                            : 'Loading...'
                                        }
                                    </div>
                                </CardContent>
                            </Card>
                        </div> */}

                        </div>
                        </div>
                        <div className='col-sm-12 col-md-2' style={{marginTop:'0.4rem'}}>
                            <div className='row'>
                                <div className='col-4'>
                                    <Button variant="outlined" onClick = {getResponse} style={{width:'max-content', backgroundColor:'darkgray', color:'black', marginBottom:'1rem'}}>View Report</Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="table-div" style={{position:'relative'}}>

                {isLoading && <CircularIndeterminate open={isLoading} />}

                {
                    tableData.length

                    ? <TableDemo columns={columnGradeResultsColumns} currentPage = {currentPage} pages = {pages} tableData = {tableData} deviceNumber={deviceNumber} setNextPage = {setNextPage} setPrevPage = {setPrevPage} updateCurrentPage = {updateCurrentPage} handlePageNumberChange = {handlePageNumberChange}/>
                    
                    : <Alert severity="info">No Data To Show</Alert>
                }
                </div>
                </div>
            </div>

        </>
    )
}

export default Home