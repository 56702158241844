export const resultsColumns = [
    {
        Header: 'Row #',
        accessor: 'row_number'
    },
    {
        Header: 'Patient Sample ID',
        accessor: 'patient_sample_id'
    },

    {
        Header: 'Test Type',
        accessor: 'test_type'
    },

    {
        Header: 'Col #',
        accessor: 'col_num'
    },

    {
        Header: 'Column Type',
        accessor: 'column_type'
    },

    {
        Header: 'Graded Result Modified',
        accessor: 'graded_result_modified'
    },

    {
        Header: 'Graded Result Original',
        accessor: 'graded_result_original'
    },

    {
        Header: 'Result Time',
        accessor: 'result_time'
    },

    {
        Header: 'Img File Name',
        accessor: 'image_file_name'
    },

    {
        Header: 'Card Barcode',
        accessor: 'card_barcode'
    },

    {
        Header: 'Card Type',
        accessor: 'card_type'
    },

    {
        Header: 'Card Lot #',
        accessor: 'card_lot_num'
    },

    {
        Header: 'Reagent1 Lot',
        accessor: 'reagent1_lot'
    },

    {
        Header: 'Device #',
        accessor: 'device_num'
    }
 ]

 export const deviceNums = [
    '80002713',
    '80002769',
    '80002770',
    '80002774',
    '80002775',
    '80002776',
    '80002777',
    '80002778',
    '80002779',
    '80002780'
 ]