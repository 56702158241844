import * as React from 'react';
import { useTranslation } from 'react-i18next'
import './Results.css'

import {get} from '../../utils/http-utils'

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TableDemo from '../../components/core/Table2';
import CircularIndeterminate from '../../components/core/loader';

import { deviceNumsContext } from '../../contexts/deviceNumContext'

import {resultsColumns,deviceNums} from './constants'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Toast from '../../components/UtilComps/Toast';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  
const Home = (props) => {

    const { deviceNumbers } = React.useContext(deviceNumsContext)

    const { t } = useTranslation();
    const [ fromDate, setFromDate] = React.useState('')
    const [ toDate , setToDate] = React.useState('')
    const [pages, setPages] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [tableData, setTableData] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1)

    const [ sampleIds, setSampleIds ] = React.useState([])
    const [ barcodes, setBarcodes] = React.useState([])

    const [isLoading, setIsLoading] = React.useState(false)

    const [ barcodesLoaded, setBarcodesLoaded ] = React.useState(true)
    const [ sampleIdsLoaded, setSampleIdsLoaded ] = React.useState(true)
    const [toastSpecs,setToastSpecs] = React.useState({})

    const handleToastClose = () => {
        setToastSpecs({})
    }

const [deviceNumber,setDeviceNumber] = React.useState('')

const [selectedBarcode, setSelectedBarcode ] = React.useState('')
const [selectedSampleId, setSelectedSampleId ] = React.useState('')

    const handleDateChange = (e,type = 'from') => {
        console.log('date changed ',e.target.value,type)
        if(type === 'from'){
            setFromDate(e.target.value)
        }else{
            setToDate(e.target.value)
        }
    }

    const handlePageNumberChange = (evt) => {
        let pgNo = evt.target.value.trim() ? Number(evt.target.value) : 0
        if(pgNo){
            if(pgNo > pages){
                setCurrentPage(pages)
            }else{
                setCurrentPage(pgNo)
            }
        }
    }

    const validateInputs = () => {
        const deviceNumberSelected = deviceNumber && deviceNumber.trim().length
        const barcodeSelected = selectedBarcode && selectedBarcode.trim().length
        const datesAreValid = new Date(toDate) > new Date(fromDate)
        return {res: deviceNumberSelected && barcodeSelected && datesAreValid, deviceNumberSelected,barcodeSelected, datesAreValid}
    }

    const triggerToast = (dn,bc,dt) => {
        if(!dn){
            setToastSpecs(({open:true, type: 'warning', msg: 'Please Select Device Number'}))
            return
        }else if(!dt) {
            setToastSpecs(({open:true, type: 'warning', msg: 'Please Select Valid Date Range'}))
            return
        }else if(!bc) {
            setToastSpecs(({open:true, type: 'warning', msg: 'Please Select Barcode'}))
            return
        }
    }

    const getResponse = async ( newValues = true ) => {
        if(newValues){
            let { res, deviceNumberSelected,barcodeSelected, datesAreValid } = validateInputs()
            if(!res){
                triggerToast(deviceNumberSelected,barcodeSelected,datesAreValid)
                return
            }
        }
        if(deviceNumber && deviceNumber.trim().length &&  fromDate && fromDate.trim().length &&  toDate && toDate.trim().length){
            setIsLoading(true)
            let numberOfPagesResponse

            if(newValues){
                let params = {
                    aPageSize: rowsPerPage,
                    bSN: deviceNumber,
                    dStartDate: fromDate,
                    eEndDate: toDate,
                    cBarcode: selectedBarcode,
                    fSampleId: selectedSampleId,
                    fSampleId2: selectedSampleId,
                }
                numberOfPagesResponse = await get('/Results/Pagination',params)
                setPages(numberOfPagesResponse.data)
            }

            let params2 = {
                pageSize: rowsPerPage,
                bSN: deviceNumber,
                dStartDate: fromDate,
                eEndDate: toDate,
                pageNum: currentPage,
                cBarcode: selectedBarcode ? selectedBarcode : '28042377161497114009',
                fSampleId: selectedSampleId,
                fSampleId2: selectedSampleId,
            }
            let tableData = await get('/Results',params2)

            console.log('numberofpages === ',numberOfPagesResponse)
            console.log(tableData)
            setTableData(tableData.data)
        }
        setIsLoading(false)
    }

    React.useEffect(() => {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        setFromDate(firstDay.toISOString().split('T')[0])
        setToDate(new Date().toISOString().split('T')[0])
    },[])

    React.useEffect(() => {
        getResponse(false)
    },[currentPage])

    React.useEffect(() => {
        if(deviceNumber.trim().length){
            fetchBarcodes()
        }
    },[deviceNumber])

    React.useEffect(() => {
        if(selectedBarcode.trim().length && deviceNumber.trim().length){
            console.log({selectedBarcode,deviceNumber})
            fetchSampleIds()
        }
    },[selectedBarcode])

    const updateCurrentPage = (pageNum) => {
        setCurrentPage(pageNum)   
    }

    const setNextPage = () => {
        setCurrentPage(currentPage + 1)
    }

    const setPrevPage = () => {
        setCurrentPage(currentPage - 1)
    }

    const fetchBarcodes = async () => {
        setBarcodesLoaded(false)
        let barcodes = await get('/Barcodes',{deviceNumber})
        console.log('barcodes === ',barcodes)
        setBarcodes(barcodes.data)
        setBarcodesLoaded(true)
    }
    

    const fetchSampleIds = async () => {
        setSampleIdsLoaded(false)
        let sampleIds = await get('/SampleIds',{aDeviceNumber:deviceNumber,bBarcode:selectedBarcode})
        console.log('sampleIds === ',sampleIds)
        setSampleIds(sampleIds.data)
        setSampleIdsLoaded(true)
    }

    return (
        <>
            <Toast specs={toastSpecs} onClose={handleToastClose} />
            <div className="main-content">
                <div className='header-logo'>
                    <div className='header-text'>Ortho</div>
                </div>

                <div className='body-content-wrapper'>
                    <div className='body-content-header'>
                        Results
                    </div>

                    <div className='body-content'>
                    <div className='input-section row' style={{width:'100%'}}>
                    <div className='col-sm-12 col-md-10'>
                            <div className='row'>
                            <div className='col-sm-12 col-md-3' style={{marginTop:'5px',  textAlign: 'left'}}>
                                <Card sx={{minWidth:'100%'}}>
                                    <CardContent>
                                        <div className='card-label'>
                                            Device #
                                        </div>
                                        <div>
                                            <select style={{width:'100%'}} onChange={(e) => setDeviceNumber(e.target.value)} value={deviceNumber}>
                                                <option value=''>Select</option>
                                                {
                                                    // deviceNumbers.map(e => <option value={e} key={e}>{e}</option>)
                                                    deviceNumbers.map(e => <option value={e.device_number} key={e.device_number}>{e.device_number}</option>)
                                                }
                                            </select>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className='col-sm-12 col-md-3' style={{marginTop:'5px',  textAlign: 'left'}}>
                                <Card sx={{minWidth:'100%'}}>
                                    <CardContent>
                                        <div className='card-label'>
                                            From
                                        </div>
                                        <div>
                                            <input type= 'date' value={fromDate} onChange = { e => handleDateChange(e,'from')}/>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className='col-sm-12 col-md-3' style={{marginTop:'5px',  textAlign: 'left'}}>
                                <Card sx={{minWidth:'100%'}}>
                                    <CardContent>
                                        <div className='card-label'>
                                            To
                                        </div>
                                        <div>
                                            <input type= 'date' value={toDate} onChange = { e => handleDateChange(e,'to')} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className='col-sm-12 col-md-3' style={{marginTop:'5px',  textAlign: 'left'}}>
                                <Card sx={{minWidth:'100%'}}>
                                    <CardContent>
                                        <div className='card-label'>
                                            Card Barcode
                                        </div>
                                        <div>
                                           {
                                                barcodesLoaded
                                                ? <select style={{width:'100%'}} value={selectedBarcode} onChange={(e) => setSelectedBarcode(e.target.value)}>
                                                        <option value=''>Select</option>
                                                        {
                                                            barcodes.length &&
                                                            barcodes.map( ({barcode}) => <option value={barcode} key={barcode}>{barcode}</option> )
                                                        }
                                                    </select>
                                                : 'Loading...'
                                            }
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className='col-sm-12 col-md-3' style={{marginTop:'5px',  textAlign: 'left'}}>
                                <Card sx={{minWidth:'100%'}}>
                                    <CardContent>
                                        <div className='card-label'>
                                            SampleID
                                        </div>
                                        <div>
                                           {
                                                sampleIdsLoaded
                                                ? <select style={{width:'100%'}} value={selectedSampleId} onChange={(e) => setSelectedSampleId(e.target.value)}>
                                                        <option value=''>Select</option>
                                                        {
                                                            sampleIds.length &&
                                                            sampleIds.map( ({sample_id}) => <option value={sample_id} key={sample_id}>{sample_id}</option> )
                                                        }
                                                    </select>
                                                : 'Loading...'
                                            }
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-2' style={{marginTop:'0.4rem'}}>
                            <div className='row'>
                                <div className='col-4'>
                                    <Button variant="outlined" onClick = {getResponse} style={{width:'max-content', backgroundColor:'darkgray', color:'black', marginBottom:'1rem'}}>View Report</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-div" style={{position:'relative'}}>
                {isLoading && <CircularIndeterminate open={isLoading} />}
                { 
                tableData.length
                    ? <TableDemo columns={resultsColumns} currentPage = {currentPage} pages = {pages} tableData = {tableData} deviceNumber={deviceNumber} setNextPage = {setNextPage} setPrevPage = {setPrevPage} updateCurrentPage = {updateCurrentPage} handlePageNumberChange = {handlePageNumberChange}/>

                    : <Alert severity="info">No Data To Show</Alert>
                }
                </div>
            </div>
            </div>
        </>
    )
}

export default Home