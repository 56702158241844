import React, { useMemo } from 'react'
import { usePagination, useTable } from 'react-table'
import './styles.css'
 
 export default function Table(props) {
   const data = useMemo(() => props.tableData.map((row,index) => ({...row,row_number:index+1,result_time:new Date(row.result_time).toLocaleString()})),[props.tableData])
 
   const columns = React.useMemo(
     () => props.columns,
     []
   )
 
   const {
     getTableProps,
     getTableBodyProps,
     headerGroups,
     page,
     nextPage,
     previousPage,
     prepareRow,
     pageOptions,
     state,
     gotoPage,
     pageCount,
     setPageSize
   } = useTable({ columns, data,initialState:{pageSize:20} }, usePagination)

   const { pageIndex,pageSize } = state
 
   return (
     <>
     <table className='orders-table' {...getTableProps()} style={{ border: 'solid 1px black' }} showPageSizeOptions= {true}
    pageSizeOptions= {[2,5,10]}  >
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th
                 {...column.getHeaderProps()}
                 style={{
                   border: 'solid 1px black',
                   background: 'red',
                   color: '#FFFFFF',
                   fontWeight: 'bold',
                 }}
               >
                 {column.render('Header')}
               </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
         {page.map(row => {
           prepareRow(row)
           return (
             <tr {...row.getRowProps()}>
               {row.cells.map(cell => {
                 return (
                   <td
                     {...cell.getCellProps()}
                     style={{
                       padding: '10px',
                       border: 'solid 1px gray',
                       wordBreak:'break-word',
                       fontSize:'0.7em',
                     }}
                   >
                     {cell.render('Cell')}
                   </td>
                 )
               })}
             </tr>
           )
         })}
       </tbody>
     </table>
     <div>
        <span>Page{' '}</span>
        <strong>{props.currentPage} of {props.pages}</strong>
        <button className="play-buttons"  onClick={() => props.updateCurrentPage(1)} disabled = {props.currentPage <= 1}>{'<<'}</button>
        <button className="play-buttons" onClick={props.setPrevPage} disabled = {props.currentPage <= 1}>Previous</button>
        <button className="play-buttons" onClick={props.setNextPage} disabled = {props.currentPage >= props.pages}>Next</button>
        <button className="play-buttons" onClick={() => props.updateCurrentPage(props.pages)} disabled={props.currentPage >= props.pages}>{'>>'}</button>
        <input type='number' value = {props.currentPage} max={props.pages ? props.pages : 1} style={{width:'100px'}} onChange={props.handlePageNumberChange} />
     </div>
     </>
   )
 }