import React, { Suspense, useEffect, useState, useContext } from 'react';
import './App.css';
import Footer from './components/core/footer/Footer'
import Header from './components/core/header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Amplify, Auth } from 'aws-amplify';
import { cognitoConfig } from './utils/cognito/CognitoConfig';
import Home from './pages/home/Home';
import { checkAppAccessibility } from './utils/accessibility-check';
import PermissionError from './components/error/permission-error/PermissionError';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TranslateEn } from './assets/i18n/translateEn';
import { TranslateFr } from './assets/i18n/translateFr';

import Results from './pages/Results/Results'
import ConditionCodes from './pages/ConditionCodes/ConditionCodes'
import ColumnGradeResults from './pages/ColumnGradeResults/ColumnGradeResults';

import { deviceNumsContext } from './contexts/deviceNumContext'
import {get} from './utils/http-utils'

import { BrowserRouter as Router, Routes} from 'react-router-dom'
import AppRoutes from './Routes';

Amplify.configure(cognitoConfig);

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: TranslateEn },
    fr: { translation: TranslateFr }
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false }
});

function App() {

  const [userSession, setUserSession] = useState(false);
  const [user, setUser] = useState(null);
  const [ deviceNumbers, setDeviceNumbers ] = useState([])

  const [currentPage, setCurrentPage] = useState('orders')

  // use this to change languge
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
  }

  const getDeviceNumbers = async () => {
    try{
        let deviceNumbers
        try{
          deviceNumbers = await get('/DeviceNumbers',{id:1})
        }catch(e){
          console.log('deviceNumbersError',e)
        }
        setDeviceNumbers(deviceNumbers.data?.length ? deviceNumbers.data : [])
    }catch(e){
        setDeviceNumbers([])
    }
}

  useEffect(() => {
    
    // Authenicate User
    Auth.currentAuthenticatedUser()
      .then((userInfo) => {
        console.log("User", userInfo);
        setUser(userInfo.attributes);
      })
      .catch(() => {
        console.log("Not signed in redirecting to sign in");
        Auth.federatedSignIn();
      });

      // Get user session and save in state
      const getSession = async () => {
        await Auth.currentSession().then(userSession => {
          setUserSession(userSession);
        });
      }
      getSession();

      getDeviceNumbers()

  }, []);

  // Check if user has permission to access the page
  const canUserAccess = () => {
    return checkAppAccessibility(user['custom:UserType']);
  }

  // signout function
  const cognitoSignOut = () => {
    Auth.signOut();
  }

  // return application component when login is valid

  console.log('current page ===',currentPage)

  const renderBodyComponent = user => {
    let comp
    switch(currentPage){
      case 'orders':
        comp = <Home user={user} />
      break
      case 'results':
        comp = <Results user={user} />
      break
      case 'condition_codes':
        comp = <ConditionCodes user={user} />
      break
      case 'column_grade_results':
        comp = <ColumnGradeResults use = {user} />
      break
      default:
        comp = <Home user={user} />
    }
    return comp
  }
  if (userSession && userSession?.isValid()) {

    return (
      user && canUserAccess() ?
      <Suspense fallback="loading...">
        <div>
        {/* <Header signout={cognitoSignOut} user={user} setCurrentPage={setCurrentPage} ></Header> */}
          <deviceNumsContext.Provider value = {{deviceNumbers}}>
            {/* { renderBodyComponent(user) } */}
                <AppRoutes user={user} signout={cognitoSignOut} setCurrentPage={setCurrentPage}/>
          </deviceNumsContext.Provider>
          {/* { currentPage === 'orders' ? <Home user={user}/> : <Results user={user} />} */}
          <Footer></Footer>
        </div>
      </Suspense>
      : <PermissionError/>
    );

  } else {

    // Navigate to signin if login is not valid
    return (
      <div>
        You will be automatically redirected or{" "}
        <a href="#" onClick={Auth.federatedSignIn}>
          click here to login
        </a>
      </div>
    );

  }
}


export default App;
