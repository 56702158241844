const app_url = 'https://www.orthoclinicaldiagnostics.com';
const url_link = {
    footer: {
        contact: app_url + '/en-us/home/contact-us',
        careers: 'https://careers.orthoclinicaldiagnostics.com/',
        ortho_plus: 'https://orthoplus.orthoclinicaldiagnostics.com/eServices/App/Public/Logon.aspx?ReturnUrl=%2feServices%2fApp%2fDefault.aspx%3fculture%3den-us&amp;culture=en-us',
        technical_documents: 'https://techdocs.orthoclinicaldiagnostics.com/TechDocs/TechDocSearch.aspx?tID=0&amp;culture=en-us',
        employee_resources: app_url + '/en-us/home/ortho-employees-resources',
        twitter: 'https://twitter.com/OrthoClinicalDX',
        facebook: 'https://www.facebook.com/OrthoClinicalDX/',
        linkedin: 'https://www.linkedin.com/company/ortho-clinical-diagnostics/',
        instagram: 'https://www.instagram.com/orthoclinicaldx/?hl=en',
        vitros_xt_7600: app_url + '/en-us/home/vitros-xt-7600',
        vitros_5600: app_url + '/en-us/home/vitros-5600',
        vitros_4600: app_url + '/en-us/home/vitros-4600',
        vitros_xt_3400: app_url + '/en-us/home/vitros-xt-3400',
        vitros_3600: app_url + '/en-us/home/vitros-3600',
        vitros_eciq: app_url + '/en-us/home/vitros-ECiQ',
        vitros_automation: app_url + '/en-us/home/vitros-automation',
        vitros_informatics: app_url + '/en-us/home/informatics',
        assay_menu: app_url + '/en-us/home/assay-menu',
        microtip_partnership: app_url + '/en-us/home/microtip-partnership-assays',
        vitros_hiv_test: app_url + '/en-us/home/vitros-hiv-combo-test',
        vitros_pct_assay: app_url + '/en-us/home/vitros_b_r_a_h_a_m_s_pct_assay',
        vision_platform: app_url + '/en-us/home/ortho-vision-platform',
        vision_max_platform: app_url + '/en-us/home/ortho-vision-platform',
        otho_connect: app_url + '/en-us/home/Ortho-connect',
        ortho_workstation: app_url + '/en-us/home/ortho-workstation',
        ortho_care: app_url + '/en-us/home/ortho-care',
        customer_exp: app_url + '/en-us/home/customer-experience',
        support: app_url + '/en-us/home/support',
        privacy_notice: app_url + '/en-us/home/privacy-notice',
        legal_notice: app_url + '/en-us/home/legal-notice',
        patent_list: app_url + '/en-us/home/patent-list',
        cookie_notice: app_url + '/en-us/home/cookie-notice-disclosure',
        ortho_notice_pdf: app_url + '/assets/pdf/CA-ORTHO-NOTICE.pdf',
        ortho_code_conduct_pdf: app_url + '/assets/pdf/US/Ortho_Code_Of_Conduct_Jan_2021.pdf',
        cybersecurity: app_url + '/en-us/home/cybersecurity',
    },
    permissionErr: {
        employee_notice:  app_url + '/en-US/employee-notice/',
        legal_notice:  app_url + '/en-US/legal-notice/',
        cookie_notice:  app_url + '/en-US/cookie-notice-disclosure/',
        ca_odc_notice:  app_url + 'File%20Library/pdf/en/CA-OCD-NOTICE.pdf',
    }
}

export default url_link;
