import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ColumnGradeResults from './pages/ColumnGradeResults/ColumnGradeResults'
import ConditionCodes from './pages/ConditionCodes/ConditionCodes'
import Home from './pages/home/Home'
import Results from './pages/Results/Results'
import Header from './components/core/header/Header'

export default function AppRoutes({user, signout,setCurrentPage}) {
    return <Router>
        <Header signout={signout} user={user} setCurrentPage={setCurrentPage} ></Header>
        <Routes>
            <Route path="/" element={<Home user={user} />} />
            <Route path="/orders" element={<Home user={user} />} />
            <Route path="/results" element={ <Results user={user} />} />
            <Route path="/condition_codes" element={ <ConditionCodes user={user} />} />
            <Route path="/column_grade_results" element={ <ColumnGradeResults user={user} />} />
        </Routes>
    </Router>
}