import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Toast({specs,onClose}) {
  const { open = false, type , msg = '' } = specs
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={onClose}
        anchorOrigin = {{vertical:'top',horizontal:'center'}}
      >
        <Alert severity={type}>{msg}</Alert>
    </Snackbar>
    </div>
  );
}
