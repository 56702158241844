export const columnGradeResultsColumns = [
    {
        Header: 'Row #',
        accessor: 'RowNum',
    },
    {
        Header: 'Instrument Type',
        accessor: 'INSTRUMENT_TYPE',
    },
    {
        Header: 'J Number',
        accessor: 'J_NUMBER',
    },
    {
        Header: 'Instrument ID',
        accessor: 'INSTRUMENT_ID',
    },
    {
        Header: 'Test Start Time',
        accessor: 'TEST_START_TIME',
    },
    {
        Header: 'Test ID',
        accessor: 'TEST_ID',
    },
    {
        Header: 'Test Type',
        accessor: 'TEST_TYPE',
    },

    {
        Header: 'Card Type',
        accessor: 'CARD_TYPE',
    },

    {
        Header: 'Patient Sample ID',
        accessor: 'PATIENT_SAMPLE_ID',
    },

    {
        Header: 'Donor Sample ID',
        accessor: 'DONOR_SAMPLE_ID',
    },

    {
        Header: 'Column Type',
        accessor: 'COLUMN_TYPE',
    },

    {
        Header: 'Column #',
        accessor: 'COLUMN_NUMBER',
    },

    {
        Header: 'Graded Result For',
        accessor: 'GRADED_RESULT_FOR_ANALYSIS',
    },

    {
        Header: 'Time of Graded Result',
        accessor: 'TIME_OF_RESULT',
    },

    {
        Header: 'Graded Result Original',
        accessor: 'GRADED_RESULT_ORIGINAL',
    },

    {
        Header: 'Graded Result Modified',
        accessor: 'GRADED_RESULT_MODIFIED',
    },

    {
        Header: 'Modified Graded Result Time',
        accessor: 'MODIFIED_GRADED_RESULT_TIME',
    },

    {
        Header: 'Modified Graded Result Operator',
        accessor: 'MODIFIED_GRADED_RESULT_OPERATOR',
    },

    {
        Header: 'Diluent Type',
        accessor: 'DILUENT_TYPE',
    },

    {
        Header: 'Diluent Lot',
        accessor: 'DILUENT_LOT',
    },

    {
        Header: 'Reagent 1 ID',
        accessor: 'REAGENT_1_ID',
    },

    {
        Header: 'Reagent 1 Lot',
        accessor: 'REAGENT_1_LOT',
    },

    {
        Header: 'Reagent 2 ID',
        accessor: 'REAGENT_2_ID',
    },

    {
        Header: 'Reagent 2 Lot',
        accessor: 'REAGENT_2_LOT',
    },

    {
        Header: 'Reagent 3 ID',
        accessor: 'REAGENT_3_ID',
    },

    {
        Header: 'Reagent 3 Lot',
        accessor: 'REAGENT_3_LOT',
    },

    {
        Header: 'Dilution Level',
        accessor: 'DILUTION_LEVEL',
    },

    {
        Header: 'Patient Sample Type 1',
        accessor: 'PATIENT_SAMPLE_TYPE_1',
    },

    {
        Header: 'Patient Sample Type 2',
        accessor: 'PATIENT_SAMPLE_TYPE_2',
    },

    {
        Header: 'Donor Type 1',
        accessor: 'DONOR_TYPE_1',
    },

    {
        Header: 'Punch Time',
        accessor: 'PUNCH_TIME',
    },

    {
        Header: 'Card Barcode',
        accessor: 'CARD_BARCODE',
    },

    {
        Header: 'Card Lot #',
        accessor: 'CARD_LOT_NUMBER',
    },

    {
        Header: 'Card ID',
        accessor: 'CARD_ID',
    },

    {
        Header: 'Sample Dispense Time',
        accessor: 'SAMPLE_DISPENSE_TIME',
    },

    {
        Header: 'Column CondCode',
        accessor: '',
    },

    {
        Header: 'Test Result ID',
        accessor: 'test_result_id',
    },

    {
        Header: 'Operator',
        accessor: 'OPERATOR',
    },

    {
        Header: 'Software Version',
        accessor: 'SOFTWARE_VERSION',
    },

    {
        Header: 'ADD Version',
        accessor: 'ADD_VERSION',
    },

    {
        Header: 'Profile Name',
        accessor: 'PROFILE_NAME',
    },

    {
        Header: 'Img File Name',
        accessor: 'IMAGE_FILE_NAME',
    },

    {
        Header: 'Accept Sate',
        accessor: 'ACCEPT_STATE',
    },

    {
        Header: 'Comments',
        accessor: '',
    },
 ]

 export const deviceNums = [
    '80002713',
    '80002769',
    '80002770',
    '80002774',
    '80002775',
    '80002776',
    '80002777',
    '80002778',
    '80002779',
    '80002780'
 ]