import './PermissionError.css';
import Grid from '@mui/material/Grid';
import Anchor from '../../elements/Anchor';
import url_link from '../../../assets/url_links';

const PermissionError = () => {
    return (
        <div className='error-container'>
            <Grid container direction="column" rowSpacing={2}>
                <Grid item>
                    <div className='header-logo'>
                        <div className='header-text'>Ortho Clinical Diagnostics</div>
                    </div>
                </Grid>
                <Grid item>
                    <div className='error-conatiner'>
                        <p className='error-text'>Permission Error</p>
                        <p>We're sorry, you don't have permission to this area of our site. Please contact your administrator to be granted access.</p>
                    </div>
                </Grid>
                <Grid item>
                    <div className='footer-conainter'>

                        <div className='footer-links'>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                            >

                                <Grid item>
                                    <Anchor url={url_link.permissionErr.employee_notice}>Employee Notice</Anchor>
                                </Grid>
                                <Grid item>
                                    <Anchor url={url_link.permissionErr.legal_notice}>Legal Notice</Anchor>
                                </Grid>
                                <Grid item>
                                    <Anchor url={url_link.permissionErr.cookie_notice}>Cookie Notice & Disclosuree</Anchor>
                                </Grid>

                                <Grid item>
                                    <Anchor url={url_link.permissionErr.ca_odc_notice}>Declaration for California Compliance</Anchor>

                                </Grid>

                            </Grid>
                        </div>

                        <p>© Ortho Clinical Diagnostics 2022. This site is published by Ortho Clinical Diagnostics, which is solely responsible for its contents. Product availability is subject to fulfillment of regulatory requirements in each market.</p>
                    </div>
                </Grid>
            </Grid>


        </div>
    )
}

export default PermissionError;