import * as React from 'react';
import { useTranslation } from 'react-i18next'
import './ConditionCodes.css'

import {get} from '../../utils/http-utils'

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TableDemo from '../../components/core/Table2';

import {condeCodesColumns,deviceNums} from './constants'
import CircularIndeterminate from '../../components/core/loader';

import { deviceNumsContext } from '../../contexts/deviceNumContext'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Toast from '../../components/UtilComps/Toast';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  
const ConditionCodes = (props) => {

    const { deviceNumbers } = React.useContext(deviceNumsContext)

    const { t } = useTranslation();
    const [ fromDate, setFromDate] = React.useState('')
    const [ toDate , setToDate] = React.useState('')
    const [pages, setPages] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const [tableData, setTableData] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(1)
    const [toastSpecs,setToastSpecs] = React.useState({})


    const [isLoading, setIsLoading] = React.useState(false)

    const handleToastClose = () => {
        setToastSpecs({})
    }

//   const handleChangePage = (event, newPage) => {
//     console.log('newpage === ',newPage)
//     setPages(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     console.log('rowsperpage === ',event.target.value)
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPages(1);
//   };
    const [deviceNumber,setDeviceNumber] = React.useState()
    const [ deviceNumbersLoaded, setDeviceNumbersLoaded] = React.useState(true)

    const handleDateChange = (e,type = 'from') => {
        console.log('date changed ',e.target.value,type)
        if(type === 'from'){
            setFromDate(e.target.value)
        }else{
            setToDate(e.target.value)
        }
    }

    const handlePageNumberChange = (evt) => {
        let pgNo = evt.target.value.trim() ? Number(evt.target.value) : 0
        if(pgNo){
            if(pgNo > pages){
                setCurrentPage(pages)
            }else{
                setCurrentPage(pgNo)
            }
        }
    }

    const validateInputs = () => {
        const deviceNumberSelected = deviceNumber && deviceNumber.trim().length
        const datesAreValid = new Date(toDate) > new Date(fromDate)
        return {res: deviceNumberSelected && datesAreValid, deviceNumberSelected, datesAreValid}
    }

    const triggerToast = (dn,dt) => {
        if(!dn){
            setToastSpecs(({open:true, type: 'warning', msg: 'Please Select Device Number'}))
            return
        }else if(!dt) {
            setToastSpecs(({open:true, type: 'warning', msg: 'Please Select Valid Date Range'}))
            return
        }
    }

    const getResponse = async (newValues = false) => {
        if(newValues){
            let { res, deviceNumberSelected, datesAreValid } = validateInputs()
            if(!res){
                triggerToast(deviceNumberSelected, datesAreValid)
                return
            }
        }
        setIsLoading(true)
        console.log('viweing report ', deviceNumber,fromDate,toDate)
        if(deviceNumber && deviceNumber.trim().length &&  fromDate && fromDate.trim().length &&  toDate && toDate.trim().length){
            let numberOfPagesResponse
            
            if(newValues){
                if(newValues){
                    let params = {
                        aPageSize: rowsPerPage,
                        bSN: deviceNumber,
                        dStartDate: fromDate,
                        eEndDate: toDate
                    }
                    numberOfPagesResponse = await get('/ConditionCodes/Pagination',params)
                    setPages(numberOfPagesResponse.data)
                }
            }
            let params2 = {
                aSN: deviceNumber,
                bStartDate: fromDate,
                cEndDate: toDate,
                pageNum: currentPage,
                pageSize: rowsPerPage,
            }
            let tableData = await get('/ConditionCodes',params2)


            console.log('numberofpages === ')
            console.log(tableData)            
            setTableData(tableData.data)
        }
        setIsLoading(false)
    }

    React.useEffect(() => {
        // getDeviceNumber()
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        setFromDate(firstDay.toISOString().split('T')[0])
        setToDate(new Date().toISOString().split('T')[0])
    },[])

    React.useEffect(() => {
        getResponse(false)
    },[currentPage])

    const updateCurrentPage = (pageNum) => {
        setCurrentPage(pageNum)   
    }

    const setNextPage = () => {
        setCurrentPage(currentPage + 1)
    }

    const setPrevPage = () => {
        setCurrentPage(currentPage - 1)
    }

    React.useEffect(() => {
        getResponse()
    },[currentPage,pages])

    const deviceNumberCard = React.useRef()
    return (
        <>
        {/* <CircularIndeterminate /> */}
        <Toast specs={toastSpecs} onClose={handleToastClose} />
            <div className="main-content">
                <div className='header-logo'>
                    {/* <div className='header-text'>{t('hello')}, {props.user.given_name}</div> */}
                    <div className='header-text'>Ortho</div>
                </div>

                <div className='body-content-wrapper'>
                    <div className='body-content-header'>
                        Condition Codes
                    </div>

                    <div className='body-content'>
                    <div className='input-section row' style={{width:'100%'}}>
                    <div className='col-sm-12 col-md-10'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-3' style={{marginTop:'5px',textAlign: 'left'}}>
                                <Card className='deviceNumberC' sx={{ minWidth: '100%'}} ref = {deviceNumberCard}>
                                    <CardContent>
                                        <div className='card-label'>
                                            Device #
                                        </div>
                                        <div>
                                            {
                                                    deviceNumbersLoaded
                                                    ? <select style={{width:'100%'}} value={deviceNumber} onChange={(e) => setDeviceNumber(e.target.value)}>
                                                        <option value=''>Select</option>
                                                        {
                                                            deviceNumbers.map(e => <option value={e.device_number} key={e.device_number}>{e.device_number}</option>)
                                                        }
                                                    </select>
                                                    :'Loading...'
                                            }
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className='col-sm-12 col-md-3' style={{marginTop:'5px', textAlign: 'left'}}>
                                <Card>
                                    <CardContent>
                                        <div className='card-label'>
                                            From: 
                                        </div>
                                        <div>
                                            <input type= 'date' value={fromDate} onChange = { e => handleDateChange(e,'from')}/>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>

                            <div className='col-sm-12 col-md-3' style={{marginTop:'5px', textAlign: 'left'}}>
                                <Card>
                                    <CardContent>
                                        <div className='card-label'>
                                            To 
                                        </div>
                                        <div>
                                            <input type= 'date' value={toDate} onChange = { e => handleDateChange(e,'to')} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>

                        <div className='col-sm-12 col-md-2 col-sm-1' style={{marginTop:'5px', textAlign: 'left'}}>
                            <div className='row'>
                                <div className='col-4'>
                                    <Button variant="outlined" onClick = {getResponse} style={{width:'max-content', backgroundColor:'darkgray', color:'black', marginBottom:'1rem'}}>View Report</Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="table-div" style={{position:'relative'}}>
                {isLoading && <CircularIndeterminate open={isLoading} />}
                {
                    tableData.length

                    ? <TableDemo columns={condeCodesColumns} currentPage = {currentPage} pages = {pages} tableData = {tableData} deviceNumber={deviceNumber} setNextPage = {setNextPage} setPrevPage = {setPrevPage} updateCurrentPage = {updateCurrentPage} handlePageNumberChange = {handlePageNumberChange} charWidth={70}/>

                    : <Alert severity="info">No Data To Show</Alert>
                }
                </div>
                </div>
            </div>
        </>
    )
}

export default ConditionCodes