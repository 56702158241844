export const ordersColumns = [
    {
        Header: 'Row #',
        accessor: 'row_number'
    },
    {
        Header: 'Patient Sample ID',
        accessor: 'patient_sample_id'
    },

    {
      Header: 'Profile Name',
      accessor: 'profile_name'
    },

    {
        Header: 'Test Type',
        accessor: 'test_type'
    },
    {
      Header: 'Test ID',
      accessor: 'testid'
    },
    {
      Header: 'Result ID',
      accessor: 'resultid'
    },

    {
      Header: 'Test Analysis',
      accessor: 'test_analysis'
    },

    {
      Header: 'Test Result Analysis',
      accessor: 'test_result_analysis'
    },

    {
      Header: 'Test Result Original',
      accessor: 'test_result_original'
    },

    {
      Header: 'Test Result Modified',
      accessor: 'test_result_modified'
    },

    {
      Header: 'Accept State',
      accessor: 'accept_state'
    },

    {
      Header: 'Operator',
      accessor: 'operator'
    },

    {
      Header: 'Barcode',
      accessor: 'barcode'
    },

    {
      Header: 'Card Type',
      accessor: 'card_type'
    },

    {
      Header: 'Card Lot #',
      accessor: 'card_lot_number'
    },

    {
      Header: 'Card Expiration',
      accessor: 'card_expiration'
    },

    {
      Header: 'Image File Name',
      accessor: 'image_file_name'
    },

    {
      Header: 'Result Image',
      accessor: 'result_image'
    },

    {
        Header: 'Col #',
        accessor: 'column_number'
    },

    {
        Header: 'Column Type',
        accessor: 'column_type'
    },

    {
      Header: 'Graded Result For Analysis',
      accessor: 'graded_result_for_analysis'
    },

    {
        Header: 'Graded Result Modified',
        accessor: 'graded_result_modified'
    },

    {
        Header: 'Graded Result Original',
        accessor: 'graded_result_original'
    },

    {
      Header: 'Regeant1 Name',
      accessor: 'reagent_1_name'
    },

    {
      Header: 'Regeant1 ID',
      accessor: 'reagent_1_id'
    },

    {
        Header: 'Regeant1 Lot',
        accessor: 'reagent_1_lot'
    },

    {
      Header: 'Test Start Time',
      accessor: 'test_starttime'
  },

    {
        Header: 'Device Number',
        accessor: 'device_num'
    },

 ]

 export const deviceNums = [
  '80002769',
  '80002770',
  '80002774',
  '80002775',
  '80002776',
  '80002777',
  '80002778',
  '80002779',
  '80002780'
]