import React, { useState } from 'react';
import './Header.css';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../../../assets/images/ortho-logo.png'
import { Link } from 'react-router-dom';

const Header = (props) => {

  const [show, setShow] = useState(false);
  const [showVisionReportsList, setShowVisionReportsList] = useState(false)

  const showDropdown = () => {
      setShow(!show);
  }
  const hideDropdown = () => {
      setShow(false);
  }

  return (
      <header className='headerContainer'>
        <Navbar expand="lg" sticky='top' >
          <Container>
            <Navbar.Brand href="#home">
              <img
                src={logo}
                width="160"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="justify-content-end" style={{ width: "100%" }}>
              <Nav.Link href="#home" className='navList'>Home</Nav.Link>
              {/* <Nav.Link href="#home" className='navList'>Version Reports</Nav.Link> */}
              <NavDropdown
                title="Vision Reports"
                id="vision-reports-dropdown"
                className="navList"
                show={showVisionReportsList}
                onMouseEnter={() => setShowVisionReportsList(true)}
                onMouseLeave={() => setShowVisionReportsList(false)}
                style={{color:'black'}}
              >
                <NavDropdown.Item>
                  <Link to="/results" style={{textDecoration:'none', color:'#000'}}>Results</Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/orders" style={{textDecoration:'none', color:'#000'}}>Orders</Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Link to="/column_grade_results" style={{textDecoration:'none', color:'#000'}}>Column Grade Results</Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Link to="/condition_codes" style={{textDecoration:'none', color:'#000'}}>Condition Codes</Link>
                </NavDropdown.Item>

              </NavDropdown>
                <NavDropdown 
                  title={`${props.user.given_name} ${props.user.family_name}`} 
                  id="basic-nav-dropdown" 
                  className='navList' 
                  show={show}
                  onMouseEnter={showDropdown} 
                  onMouseLeave={hideDropdown}
                  style={{ color: "black" }}>

                  <NavDropdown.Item>
                    {`${props.user.given_name} ${props.user.family_name}`}
                    <br/>
                    <div>
                      <a 
                        className='dropdownLink'
                        href={`mailto:${props.user.email}`}
                      >{props.user.email}</a>
                    </div>
                  </NavDropdown.Item>
                  <NavDropdown.Item >
                    <div onClick={() => {props.signout()}}>Logout</div>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    )
}

export default Header;
