import { Amplify, Auth } from 'aws-amplify';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,

        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,//your_cognito_domain
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: process.env.REACT_APP_HOME_URL,//redirect url after sign in
            redirectSignOut: process.env.REACT_APP_LOGOUT_URL,//redirect url after sign out
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});

export const cognitoConfig = Auth.configure();
