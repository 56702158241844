export const condeCodesColumns = [
    {
        Header: 'Row #',
        accessor: 'rownum',

    },
    {
        Header:'Device #',
        accessor:'SerialNumber'
    },
    {
        Header:'Date',
        accessor:'ErrorTimeStamp'
    },
    {
        Header:'Cond Code',
        accessor:'ErrorCode'
    },
    {
        Header:'Event Sequence',
        accessor:''
    },
    {
        Header:'Severity',
        accessor:'Severity'
    },

    {
        Header:'Display Color',
        accessor:'DisplayColor'
    },

    {
        Header:'Actual Short Text',
        accessor:'ActualShortText'
    },
    {
        Header:'Generic Short Text',
        accessor:'GenericShortText'
    },

    {
        Header:'Condition Details',
        accessor:'ContextInfoParams'
    },
    {
        Header:'Actual Long Text',
        accessor:'ActualLongText'
    },
 ]

 export const deviceNums = [
    '80002713',
    '80002769',
    '80002770',
    '80002774',
    '80002775',
    '80002776',
    '80002777',
    '80002778',
    '80002779',
    '80002780'
 ]