import React from "react";

const Anchor = (props) => {
    return (
        <a href={props.url} className={props.cls} target="_blank" rel="noreferrer nofollow">
            {props.children}
        </a>
    )
}

export default Anchor;
