import axios from 'axios';
import { API_GATEWAY, DEPLOYMENT_REGION,API_GATEWAY_STAGING } from './constants';
import { Auth } from 'aws-amplify'

// const API_GATEWAY='https://1skpwbrrei.execute-api.us-east-1.amazonaws.com/'
// const DEPLOYMENT_REGION='USA'
// const API_GATEWAY_STAGING='dev'

export async function get(url, params = {},headers = { "content-type": "application/json" }) {
    let accessToken;
    let jwt;
    let cognitoSession;
    const completeURL = API_GATEWAY+API_GATEWAY_STAGING

    if (DEPLOYMENT_REGION === 'USA') {
        await Auth.currentSession().then(res => {
            accessToken = res.getAccessToken();

            jwt = res.getIdToken().getJwtToken();

            cognitoSession = res;
        });

        if (cognitoSession.isValid()) {
            headers.authorization = "Bearer " + jwt;
        } else {
            Auth.federatedSignIn();
            return "";
        }
    } else {
        console.log(localStorage.getItem('keycloakToken'))
        headers.authorization = `Bearer ${localStorage.getItem('keycloakToken')}`
    }
    
    return axios.get(completeURL + url, { headers, params });
}