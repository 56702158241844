import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import './Footer.css';
import logo from '../../../assets/OrthoLogoTag_White.png';
import url_link from '../../../assets/url_links';
import Anchor from '../../elements/Anchor';

const Footer = () => {
    return (
        <footer>

            <div className='footer container'>
                <Row>
                    <Col xs={10}>
                        <div className='footer__quick-links footer__quick-links--primary'>
                            <ul className='list-unstyled'>
                                <li>
                                    <Anchor url={url_link.footer.contact}>Contact</Anchor>
                                </li>
                                <li>
                                    <Anchor url={url_link.footer.careers}>Careers</Anchor>
                                </li>
                                <li>
                                    <Anchor url={url_link.footer.ortho_plus}>Ortho Plus</Anchor>
                                </li>
                                <li>
                                    <Anchor url={url_link.footer.technical_documents}>Technical Documents</Anchor>
                                </li>
                                <li>
                                    <Anchor url={url_link.footer.employee_resources}>Employee Resources</Anchor>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col>
                        <div className='col-auto d-none d-md-block'>
                            <div className='footer__quick-links footer__quick-links--primary footer__quick-links--social'>
                                <ul>
                                    <li>
                                        <Anchor url={url_link.footer.twitter}>
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </Anchor>
                                    </li>
                                    <li>
                                        <Anchor url={url_link.footer.facebook}>
                                            <FontAwesomeIcon icon={faFacebookF} />
                                        </Anchor>
                                    </li>
                                    <li>
                                        <Anchor url={url_link.footer.linkedin}>
                                            <FontAwesomeIcon icon={faLinkedinIn} />
                                        </Anchor>
                                    </li>
                                    <li>
                                        <Anchor url={url_link.footer.instagram}>
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </Anchor>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <div className="divider-md"></div>
                </Row>

                <div className="footer-content">
                    <Row>

                        <Col md={6}>
                            <ul className="footer-menus clearfix">
                                <li className="col-xs-12 col-sm-12 col-md-5ths col-md-5ths--2x">
                                    <nav>
                                        <h4 className="title" data-toggle="ci-links">Chemistry and Immunodiagnostics
                                            <FontAwesomeIcon className="icon footer-drop-down d-block d-md-none " icon={faCaretDown} />
                                        </h4>
                                        <ul className="footer-list" id="ci-links}">
                                            <li>
                                                <Anchor url={url_link.footer.vitros_xt_7600}>VITROS® XT 7600 Integrated System</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.vitros_5600}>VITROS® 5600 Integrated System</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.vitros_4600}>VITROS® 4600 Chemistry System</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.vitros_xt_3400}>VITROS® XT 3400 Chemistry System</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.vitros_3600}>VITROS® 3600 Immunodiagnostic System</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.vitros_eciq}>VITROS® ECiQ Immunodiagnostic System</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.vitros_automation}>VITROS® Automation Solutions</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.vitros_informatics}>Informatics Solutions</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.assay_menu}>Assay Menu by Disease State</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.microtip_partnership}>Microtip Partnership Assays</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.vitros_hiv_test}>VITROS® HIV Combo Test</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.vitros_pct_assay}>VITROS® B•R•A•H•M•S PCT Assay</Anchor>
                                            </li>
                                        </ul>
                                    </nav>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <ul className="footer-menus clearfix">
                                <li className="col-xs-12 col-sm-12 col-md-5ths">
                                    <nav>
                                        <h4 className="title" data-toggle="tm-links">Transfusion Medicine 
                                            <FontAwesomeIcon className="icon footer-drop-down d-block d-md-none " icon={faCaretDown}/>
                                        </h4>
                                        <ul className="footer-list" id="tm-links">
                                            <li>
                                                <Anchor url={url_link.footer.vision_platform}>ORTHO VISION® Analyzer</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.vision_max_platform}>ORTHO VISION® Max Analyzer</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.otho_connect}>ORTHO CONNECT™</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.ortho_workstation}>ORTHO™ Workstation</Anchor>
                                            </li>
                                        </ul>
                                    </nav>
                                </li>
                            </ul>
                        </Col>
                        <Col md={3}>
                            <ul className="footer-menus clearfix">
                                <li className="col-xs-12 col-sm-12 col-md-5ths">
                                    <nav>
                                        <h4 className="title" data-toggle="support-links">Service and Support 
                                            <FontAwesomeIcon className="icon footer-drop-down d-block d-md-none " icon={faCaretDown}/>
                                        </h4>
                                        <ul className="footer-list" id="support-links">
                                            <li>
                                                <Anchor url={url_link.footer.ortho_care}>Ortho Care</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.customer_exp}>Customer Experience</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.support}>Connect &amp; Support</Anchor>
                                            </li>
                                            <li>
                                                <Anchor url={url_link.footer.educate}>Educate &amp; Optimize</Anchor>
                                            </li>
                                        </ul>
                                    </nav>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col xs={9} sm={9} md={9} lg={9}>
                        <div className="footer__quick-links  text-capitalize">
                            <ul className="list-unstyled">
                                <li>
                                    <Anchor url={url_link.footer.privacy_notice} cls='text-inherit'>Privacy Notic</Anchor>
                                </li>
                                <li>
                                    <Anchor url={url_link.footer.legal_notice} cls='text-inherit'>Legal Notice</Anchor>
                                </li>
                                <li>
                                    <Anchor url={url_link.footer.patent_list} cls='text-inherit'>Patents</Anchor>
                                </li>
                                <li>
                                    <Anchor url={url_link.footer.cookie_notice} cls='text-inherit'>Cookie Notice &amp; Disclosure</Anchor>
                                </li>
                                <li>
                                    <Anchor url={url_link.footer.ortho_notice_pdf} cls='text-inherit'>Declaration for California Compliance for Healthcare Providers</Anchor>
                                </li>
                                <li>
                                    <Anchor url={url_link.footer.ortho_code_conduct_pdf} cls='text-inherit'>Our Code of Conduct</Anchor>
                                </li>
                                <li>
                                    <Anchor url={url_link.footer.cybersecurity} cls='text-inherit'>Cybersecurity</Anchor>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3}>
                        <img src={logo} className='orthoLogoTag' alt='logo' />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="col-12 col-xl-12">
                            <span className="footer__copyright">
                                <sup><small>Ⓒ</small></sup>
                                2022 Ortho Clinical Diagnostics. Ortho Clinical Diagnostics, publishes this site and is solely responsible for its content. The availability of the products is subject to compliance with the regulatory requirements of each market. <br/>*This website contains information which is targeted to a wide range of audiences and could contain product details or information otherwise not valid or applicable to your country. Please remember to consult your local legal restrictions, regulations, registrations or intended uses in the country of your origin. Product availability may vary from country to country and is subject to varying regulatory requirements.<br/><br/>Not currently available in the USA<br/>
                            </span>
                        </div>
                    </Col>
                </Row>

            </div>
        </footer>
    )
}
export default Footer;
